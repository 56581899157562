import React from 'react';
import { useTranslate } from '@hooks';

const DocsTrackingOverview = (props) => {
  const { tHtml } = useTranslate('docs', 'trackingOverview');

  return <div {...props}>{tHtml('overview.body')}</div>;
};

export default React.memo(DocsTrackingOverview);
