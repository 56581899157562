export const awsConfig = {
  credentials: {
    accessKeyId: process.env.NEXT_PUBLIC_AWS_S3_ACCESS_KEY,
    secretAccessKey: process.env.NEXT_PUBLIC_AWS_S3_SECRET_KEY,
    region: process.env.NEXT_PUBLIC_AWS_S3_REGION,
  },
  buckets: {
    public: process.env.NEXT_PUBLIC_CDN_BUCKET_NAME,
    private: process.env.NEXT_PUBLIC_PRIVATE_BUCKET_NAME,
  },
  baseCdnUrl: process.env.NEXT_PUBLIC_CDN_URL,
  basePath: process.env.NODE_ENV,
};

export const envVars = Object.freeze({
  actionCableUrl: process.env.NEXT_PUBLIC_ACTION_CABLE_URL,
  appApiUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
  apiV2BaseUrl: process.env.NEXT_PUBLIC_API_V2_BASE_URL,
  easyStoreAppId: process.env.NEXT_PUBLIC_EASY_STORE_APP_ID,
  googleRecaptchaSiteKey: process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY,
  landingPageUrl: process.env.NEXT_PUBLIC_LANDING_PAGE_URL,
});

export const isProduction = process.env.NODE_ENV === 'production';
