import React from 'react';
import { useTranslate } from '@hooks';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import { TablePaginated, OfferVariantStatusBadge } from '@dotone/react-ui-core';
import FilterForm from './AffiliationFilterForm';
import FilterList from './AffiliationFilterList';

const formatOfferName = (cell, row) => getNameWithId(row.offer);

const formatStatus = (cell) => <OfferVariantStatusBadge value={cell} />;

const getColumns = ({ t, dataTotal }) => [
  {
    dataField: 'offerId',
    text: t('offerId'),
    formatter: formatOfferName,
    footer: t('total'),
  },
  {
    dataField: 'offer.status',
    text: t('offerStatus'),
    align: 'center',
    headerAlign: 'center',
    formatter: formatStatus,
    footer: '',
  },
  {
    dataField: 'applied',
    text: t('applied'),
    align: 'right',
    headerAlign: 'right',
    footerAlign: 'right',
    footer: () => dataTotal.applied,
  },
  {
    dataField: 'totalApplied',
    text: t('totalApplied'),
    align: 'right',
    headerAlign: 'right',
    footerAlign: 'right',
    footer: () => dataTotal.totalApplied,
  },
  {
    dataField: 'clicks',
    text: t('clicks'),
    align: 'right',
    headerAlign: 'right',
    footerAlign: 'right',
    footer: () => dataTotal.clicks,
  },
  {
    dataField: 'captured',
    text: t('captured'),
    align: 'right',
    headerAlign: 'right',
    footerAlign: 'right',
    footer: () => dataTotal.captured,
  },
];

const AffiliationTable = ({ dataTotal = {}, ...otherProps }) => {
  const { t } = useTranslate('affiliation', 'model');
  const columns = getColumns({ t, dataTotal });

  const tableProps = {
    classes: 'text-sm stylish-table',
    columns,
    responsive: true,
    condensed: true,
    filterListRenderer: (filters) => <FilterList filters={filters} />,
    filterRenderer: (filterProps) => <FilterForm {...filterProps} />,
    hover: true,
    keyField: 'offerId',
    remote: true,
    ...otherProps,
  };

  return <TablePaginated {...tableProps} />;
};

export default AffiliationTable;
