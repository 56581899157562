import React from 'react';
import {
  ImageWithPreview,
  ButtonFlat,
  CreativeStatusBadge,
  FlexContainer,
  TextCreativeDiscount,
} from '@dotone/react-ui-core';
import { Pause as PauseIcon, Link as LinkIcon, Archive as ArchiveIcon } from 'react-feather';
import { creativeStatuses } from '@dotone/react-ui-core/constants';
import TextCreativeFormButton from './TextCreativeFormButton';

export const formatOfferName = (cell, row) => (
  <>
    ({row.offer.id}) {cell}
  </>
);

export const formatImageCreativePreview = (cell) => (
  <ImageWithPreview src={cell} previewSrc={cell} alt={`Banner: {row.id}`} size={100} />
);

export const formatActions = (t, onChangeStatus, onRejectReason) =>
  function RenderActions(cell, row) {
    const statusProps =
      row.status === creativeStatuses.REJECTED
        ? {
            onClick: () => onRejectReason(row),
            style: { cursor: 'pointer' },
          }
        : {};

    return (
      <FlexContainer column align="center">
        <CreativeStatusBadge value={row.status} {...statusProps} />
        <FlexContainer align="center" className="mt-2">
          <TextCreativeFormButton textCreative={row} />
          <ButtonFlat
            id={`text-creative-pause-button-${row.id}`}
            color="warning"
            className="ml-2"
            title={t('textCreative:hints.pause')}
            onClick={() => onChangeStatus(row, creativeStatuses.PAUSED)}
            confirm
            confirmMessage={t('textCreative:confirmMessages.paused')}
            disabled={row.status === creativeStatuses.PAUSED}
          >
            <PauseIcon size={18} />
          </ButtonFlat>
          <ButtonFlat
            id={`text-creative-archive-button-${row.id}`}
            color="danger"
            className="ml-2"
            title={t('textCreative:hints.archive')}
            onClick={() => onChangeStatus(row, creativeStatuses.SUSPENDED)}
            confirm
            confirmMessage={t('textCreative:confirmMessages.suspended')}
            disabled={row.status === creativeStatuses.SUSPENDED}
          >
            <ArchiveIcon size={18} />
          </ButtonFlat>
        </FlexContainer>
      </FlexContainer>
    );
  };

export const formatFeeContent = (cell, row) => {
  return (
    <>
      <strong>{row.title}</strong>
      <p>{row.content1}</p>
      <TextCreativeDiscount creative={row} />
      <a target="_blank" href={row.clientUrl} rel="noreferrer">
        {row.buttonText}
      </a>
    </>
  );
};

export const formatPreview = (onPreview, onLandingPage, t) =>
  function RenderPreview(cell, row) {
    return (
      <div>
        <ButtonFlat
          id={`preview-text-creative-button-${row.id}`}
          variant="preview"
          iconProps={{ size: 18 }}
          className="mr-2"
          title={t && t('textCreative:hints.preview')}
          onClick={() => onPreview(row)}
        />
        <ButtonFlat
          id={`text-creative-landing-page-url-button-${row.id}`}
          title={t && t('textCreative:hints.clientUrl')}
          onClick={() => onLandingPage(row)}
          color="dark"
        >
          <LinkIcon size={18} />
        </ButtonFlat>
      </div>
    );
  };
