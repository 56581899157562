import { AffiliateStatApi } from '@api';
import { upperCase } from 'lodash';
import { statTypes as actionTypes } from './types';

export const fetchStatList = (params) => (dispatch) => {
  const { dataType } = params;
  const actionType = actionTypes[`LIST_${upperCase(dataType)}`];

  dispatch({
    type: actionType.LOADING,
  });

  AffiliateStatApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionType.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionType.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const fetchPendingStats = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST_PENDING.LOADING,
  });

  AffiliateStatApi.getPending(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST_PENDING.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST_PENDING.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const fetchPendingByOfferStats = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST_PENDING_BY_OFFER.LOADING,
  });

  AffiliateStatApi.getPendingByOffer(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST_PENDING_BY_OFFER.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST_PENDING_BY_OFFER.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const downloadStats = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.DOWNLOAD.LOADING,
  });

  AffiliateStatApi.download(params)
    .then((response) => {
      dispatch({
        type: actionTypes.DOWNLOAD.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.DOWNLOAD.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const searchStats = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH.LOADING,
  });

  AffiliateStatApi.search(params)
    .then((response) => {
      dispatch({
        type: actionTypes.SEARCH.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.SEARCH.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const bulkUpdateStats = (ids, params) => (dispatch) => {
  dispatch({
    type: actionTypes.BULK_UPDATE.LOADING,
  });

  AffiliateStatApi.bulkUpdate({ ids, affiliateStat: params })
    .then((response) => {
      dispatch({
        type: actionTypes.BULK_UPDATE.SUCCESS,
        payload: response,
        message: 'notify:general.delayedSave.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.BULK_UPDATE.FAILURE,
        message: `notify:general.save.error.${error.status}`,
      });
    });
};
