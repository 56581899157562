import { OfferApi } from '@api/v1';
import { offerTypes as actionTypes } from './types';

export const getOffer = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.CURRENT.LOADING,
  });

  OfferApi.get(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.CURRENT.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CURRENT.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
