import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useTableState } from '@dotone/react-ui-core/hooks';
import { useCurrentUser, useFilterSelector, useShallowEqualSelector, useTranslate } from '@hooks';
import { fetchStatSummaries, downloadStatSummaries } from '@actions';
import { statSummaryDefaultColumns } from '@constants';
import { CardPage, DownloadDropdown, PeriodTabNav } from '@dotone/react-ui-core';
import { LayoutProtected as Layout } from '@layouts';
import { camelCase, snakeCase } from 'lodash';
import StatSummaryTable from './StatSummaryTable';

const StatSummaryPerformancePage = ({ reportType, ...props }) => {
  const [downloading, setDownloading] = useState(false);
  const { t } = useTranslate('statSummary', `pages.${reportType}Performance.index`);
  const dispatch = useDispatch();
  const { currentUser, currency, timeZone, locale } = useCurrentUser();

  const defaultColumns = [...statSummaryDefaultColumns.performance, camelCase(`${reportType}_id`)];
  const [period, setPeriod] = useState('all');
  const [tableProps, setTableProps] = useTableState({
    dateable: true,
    defaultColumns,
    columnType: `${reportType}PerformanceReport`,
  });

  const router = useRouter();
  const { filters, columns, sortField, sortOrder, page, perPage } = tableProps;
  const periodical = period && period !== 'all';
  const queryColumns = [
    ...(columns.length ? columns : defaultColumns),
    periodical ? 'date' : null,
  ].filter((col) => col);
  const query = {
    locale,
    currency,
    timeZone,
    ...filters,
    dimension: snakeCase(`${reportType}Id`),
    period: periodical ? period : null,
    sortField,
    sortOrder,
    page,
    perPage,
    columns: queryColumns,
    ...(periodical && { sortField: sortField || 'date', sortOrder: sortOrder || 'desc' }),
    dateType: snakeCase('recordedAt'),
  };

  const { downloadStatus } = useShallowEqualSelector(({ statSummaryDownload }) => ({
    downloadStatus: statSummaryDownload.status,
  }));

  const { data, total, isLoading, pagination } = useFilterSelector(
    ({ statSummaryList }) => statSummaryList,
    {
      stateKey: reportType,
    }
  );

  const handleDownload = (downloadFormat) => {
    setDownloading(true);
    dispatch(downloadStatSummaries({ ...query, downloadTitle: t('title'), downloadFormat }));
  };

  useDeepCompareEffect(() => {
    if (currentUser.id) {
      dispatch(fetchStatSummaries(query, reportType));
    }
  }, [dispatch, currentUser.id, query, reportType]);

  useEffect(() => {
    if (downloading && downloadStatus === 'success') {
      router.push('/advertisers/downloads');
    }
  }, [router, downloading, downloadStatus]);

  return (
    <Layout title={t('title')} {...props}>
      <CardPage table strict title={t('title')} subtitle={t('subtitle')}>
        <StatSummaryTable
          periodical={periodical}
          header={<PeriodTabNav activeTab={period} onClick={setPeriod} />}
          tableProps={tableProps}
          loading={isLoading}
          data={data}
          dataTotal={data.length ? total : {}}
          pagination={pagination}
          onTableChange={setTableProps}
          actions={
            <DownloadDropdown
              disabled={!data.length || isLoading}
              buttonProps={{ submitting: downloading }}
              onClick={handleDownload}
            />
          }
        />
      </CardPage>
    </Layout>
  );
};

export default StatSummaryPerformancePage;
