import React from 'react';
import { useTranslate } from '@hooks';
import { getNameWithId } from '@dotone/react-ui-core/utils';
import Trans from 'next-translate/Trans';
import { Row, Col } from 'reactstrap';
import { InputClipboard, CodeClipboard } from '@dotone/react-ui-core';
import DocConversionStepTabset from './DocConversionStepTabset';

const Clipboard = (props) => <CodeClipboard className="pb-2" language="html" {...props} />;

const Translate = ({ tKey, components, code, clipboardProps, ...otherProps }) => (
  <Trans
    i18nKey={`docs:gtmScript.${tKey}`}
    components={{
      h5: <h5 className="text-dark-light" />,
      li: <li />,
      ol: <ol />,
      strong: <strong />,
      em: <em />,
      code: <Clipboard value={code} {...clipboardProps} />,
      ...components,
    }}
    {...otherProps}
  />
);

const RenderTranslate = ({ conversionStep, ...otherProps }) => {
  const { id, conversionPixel, label } = conversionStep;
  return (
    <Translate
      key={id}
      tKey="conversionPixel"
      code={conversionPixel}
      values={{ label }}
      {...otherProps}
    />
  );
};

const DocMktSite = ({ mktSite }) => {
  const { t } = useTranslate('docs', 'gtmScript');
  const { offer, network, browsePixel, gtmScript } = mktSite;
  const { conversionSteps } = offer;

  return (
    <>
      <Row>
        <Col md={6}>
          <InputClipboard
            prepend={t('common:model.offer')}
            value={getNameWithId(offer)}
            className="mb-3"
            iconButton
          />
        </Col>
        <Col md={6}>
          <InputClipboard
            prepend={t('common:model.network')}
            value={getNameWithId(network)}
            className="mb-3"
            iconButton
          />
        </Col>
      </Row>
      <Translate tKey="newDataLayer" />
      <Translate tKey="browsePixel" code={browsePixel} />
      <Translate
        tKey="conversionPixels"
        components={{
          code: (
            <div>
              <DocConversionStepTabset
                withActions
                conversionSteps={conversionSteps}
                renderer={(conversionStep) => (
                  <RenderTranslate conversionStep={conversionStep} className="border-top" />
                )}
              />
            </div>
          ),
        }}
      />
      <Translate tKey="gtmScript" code={gtmScript} clipboardProps={{ language: 'javascript' }} />
    </>
  );
};

export default DocMktSite;
