import React from 'react';
import { useRouter } from 'next/router';
import { useTranslate } from '@hooks';
import { envVars } from '@constants';
import { InputClipboard, Text } from '@dotone/react-ui-core';

const useApiUrl = () => {
  const router = useRouter();
  const { api_key: apiKey } = router.query;

  const apiUrl = (givenParams = {}) => {
    const url = new URL(envVars.apiV2BaseUrl);
    url.pathname = `${url.pathname}/advertisers/orders/modify`;
    const params = new URLSearchParams({ api_key: apiKey, ...givenParams });
    url.search = params.toString();

    return url.toString();
  };

  return { apiUrl, apiKey };
};

const Section = (props) => <div className="mb-3" {...props} />;

const UrlBlock = ({ title, subtitle, urlParams }) => {
  const { apiUrl } = useApiUrl();

  return (
    <>
      {title && <h6 className="text-dark-light">{title}</h6>}
      <Text>{subtitle}</Text>
      <InputClipboard
        value={apiUrl({
          order: 'test-order-123',
          order_total: 5400,
          ...urlParams,
        })}
        className="mb-3"
      />
    </>
  );
};

const DocModifyOrder = ({ offer, ...otherProps }) => {
  const { t, tHtml } = useTranslate('docs', 'modifyOrder');
  const { apiKey, apiUrl } = useApiUrl();

  return (
    <div {...otherProps}>
      <Section>
        <h6 className="text-dark-light">{t('requestUrl')}</h6>
        <InputClipboard value={apiUrl()} className="mb-3" />
      </Section>

      <Section>
        <div>{tHtml('params', { apiKey })}</div>
      </Section>

      <Section>
        <UrlBlock
          title={t('sampleCps.title')}
          subtitle={tHtml('sampleCps.withOrderTotal')}
          urlParams={{ status: 'adjust', offer_id: offer.id }}
        />
        <UrlBlock
          subtitle={tHtml('sampleCps.withRevenue')}
          urlParams={{ status: 'confirm', revenue: 5400, offer_id: offer.id }}
        />
      </Section>

      <Section>
        <UrlBlock
          title={t('sampleCpl.title')}
          subtitle={tHtml('sampleCpl.withStep')}
          urlParams={{
            server_subid: '9142ef919dd612b6add8e625ee212f2b',
            status: 'confirm',
            step: 'sale',
          }}
        />
        <UrlBlock
          subtitle={tHtml('sampleCpl.withServerSubid')}
          urlParams={{ server_subid: '9142ef919dd612b6add8e625ee212f2b', status: 'confirm' }}
        />
      </Section>

      <Section>
        <div>{tHtml('response', { apiKey })}</div>
      </Section>
    </div>
  );
};

export default DocModifyOrder;
