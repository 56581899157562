import { buildStateTypes as buildTypes } from '@dotone/react-ui-core/helpers';

export const authTypes = {
  ...buildTypes('AUTH'),
  DEAUTH: 'DEAUTH',
  INSTAGRAM: 'INSTAGRAM',
  REAUTH: 'REAUTH',
};

export const balanceTypes = buildTypes('BALANCE_LIST');
export const categoryGroupTypes = buildTypes('CATEGORY_GROUP_LIST');
export const countryTypes = buildTypes('COUNTRY_LIST');
export const currencyTypes = buildTypes('CURRENCY_LIST');
export const timezoneTypes = buildTypes('TIMEZONE_LIST');
export const mediaCategoryTypes = buildTypes('MEDIA_CATEGORY_LIST');

export const affiliateLogTypes = {
  CREATE: buildTypes('AFFILIATE_LOG_CREATE'),
};

export const affiliateTypes = {
  LIST: buildTypes('AFFILIATE_LIST'),
  UPDATE: buildTypes('AFFILIATE_UPDATE'),
  LIST_RECENT: buildTypes('AFFILIATE_RECENT_LIST'),
  SEARCH: buildTypes('AFFILIATE_SEARCH'),
};

export const appConfigTypes = buildTypes('APP_CONFIG');

export const apiKeyTypes = {
  LIST: buildTypes('API_KEY_LIST'),
  CREATE: buildTypes('API_KEY_CREATE'),
  UPDATE: buildTypes('API_KEY_UPDATE'),
  DELETE: buildTypes('API_KEY_DELETE'),
};

export const campaignTypes = {
  LIST: buildTypes('CAMPAIGN_LIST'),
  UPDATE: buildTypes('CAMPAIGN_UPDATE'),
};

export const chatRoomTypes = {
  CREATE: buildTypes('CHAT_ROOM_CREATE'),
  GET: buildTypes('CHAT_ROOM_CURRENT'),
  LIST: buildTypes('CHAT_ROOM_LIST'),
};

export const chatMessageTypes = {
  CREATE: buildTypes('CHAT_MESSAGE_CREATE'),
  LIST: buildTypes('CHAT_MESSAGE_LIST'),
  LIST_UPLOADED_IMAGES: buildTypes('LIST_UPLOADED_IMAGES'),
  RESET: buildTypes('CHAT_MESSAGE_RESET'),
};

export const contactTypes = {
  CREATE: buildTypes('CONTACT_CREATE'),
  LIST: buildTypes('CONTACT_LIST'),
  UPDATE: buildTypes('CONTACT_UPDATE'),
};

export const downloadTypes = {
  LIST: buildTypes('DOWNLOAD_LIST'),
  DELETE: buildTypes('DOWNLOAD_DELETE'),
};

export const easyStoreSetupTypes = {
  CURRENT: buildTypes('EASY_STORE_SETUP_CURRENT'),
  CREATE: buildTypes('EASY_STORE_SETUP_CREATE'),
};

export const faqTypes = {
  LIST: buildTypes('FAQ_LIST'),
};

export const feedTypes = {
  LIST: buildTypes('FEED_LIST'),
};

export const imageCreativeTypes = {
  CREATE: buildTypes('IMAGE_CREATIVE_CREATE'),
  LIST: buildTypes('IMAGE_CREATIVE_LIST'),
  UPDATE: buildTypes('IMAGE_CREATIVE_UPDATE'),
  BULK_UPDATE: buildTypes('IMAGE_CREATIVE_BULK_UPDATE'),
};

export const managerTypes = {
  LIST: buildTypes('MANAGER_LIST'),
  SET_CURRENT: buildTypes('CURRENT_MANAGER'),
};

export const missingOrderTypes = {
  DOWNLOAD: buildTypes('MISSING_ORDER_DOWNLOAD'),
  LIST: buildTypes('MISSING_ORDER_LIST'),
  UPDATE: buildTypes('MISSING_ORDER_UPDATE'),
};

export const mktSiteTypes = {
  GET_CODE: buildTypes('MKT_SITE_CODE'),
};

export const offerTypes = {
  LIST: buildTypes('OFFER_LIST'),
  SEARCH: buildTypes('OFFER_SEARCH'),
};

export const orderTypes = {
  UPDATE: buildTypes('ORDER_UPDATE'),
};

export const passwordTypes = {
  RESET: buildTypes('PASSWORD_RESET'),
  CREATE: buildTypes('PASSWORD_CREATE'),
  UPDATE: buildTypes('PASSWORD_UPDATE'),
};

export const productTypes = {
  LIST: buildTypes('PRODUCT_LIST'),
};

export const reportTypes = {
  LIST_STAT_SUMMARY: buildTypes('STAT_SUMMARY_LIST'),
  LIST_AFFILIATION: buildTypes('AFFILIATIONS_LIST'),
  DOWNLOAD_STAT_SUMMARY: buildTypes('STAT_SUMMARY_DOWNLOAD'),
};

export const siteInfoTypes = {
  GET_SITE_INFO_IMPRESSIONS: buildTypes('SITE_INFO_IMPRESSIONS'),
};

export const statTypes = {
  LIST_CLICKS: buildTypes('STAT_CLICKS_LIST'),
  LIST_CAPTURED: buildTypes('STAT_CAPTURED_LIST'),
  LIST_PUBLISHED: buildTypes('STAT_PUBLISHED_LIST'),
  LIST_CONVERTED: buildTypes('STAT_CONVERTED_LIST'),
  LIST_PENDING: buildTypes('STAT_PENDING_LIST'),
  LIST_PENDING_BY_OFFER: buildTypes('STAT_PENDING_BY_OFFER_LIST'),
  SEARCH: buildTypes('STAT_SEARCH'),
  BULK_UPDATE: buildTypes('STAT_BULK_UPDATE'),
  DOWNLOAD: buildTypes('STAT_DOWNLOAd'),
};

export const textCreativeTypes = {
  CREATE: buildTypes('TEXT_CREATIVE_CREATE'),
  LIST: buildTypes('TEXT_CREATIVE_LIST'),
  UPDATE: buildTypes('TEXT_CREATIVE_UPDATE'),
  BULK_UPDATE: buildTypes('TEXT_CREATIVE_BULK_UPDATE'),
};

export const uploadTypes = {
  LIST: buildTypes('UPLOAD_LIST'),
  CREATE: buildTypes('UPLOAD_CREATE'),
};

export const userTypes = {
  CURRENT: buildTypes('CURRENT_USER'),
  REGISTER: buildTypes('USER_REGISTER'),
  UPDATE: buildTypes('USER_UPDATE'),
};

export const dashboardTypes = {
  ACCOUNT_OVERVIEW: buildTypes('DASHBOARD_ACCOUNT_OVERVIEW'),
  COMMISSION_BALANCES: buildTypes('COMMISSION_BALANCES'),
  EXPOSURES: buildTypes('DASHBOARD_EXPOSURES'),
  MONTHLY_STATS: buildTypes('MONTHLY_STATS'),
  PERFORMANCE_STATS: buildTypes('DASHBOARD_PERFORMANCE_STATS'),
  RECENT_CONVERSIONS: buildTypes('RECENT_CONVERSIONS'),
  RECENT_FEEDS_NETWORK: buildTypes('RECENT_FEEDS_NETWORK'),
  RECENT_FEEDS_AFFILIATE: buildTypes('RECENT_FEEDS_AFFILIATE'),
  TOP_PUBLISHERS: buildTypes('TOP_PUBLISHER'),
  TOTAL_VISITORS: buildTypes('TOTAL_VISITORS'),
};
