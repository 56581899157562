import { ApiKeyApi } from '@api';
import { apiKeyTypes as actionTypes } from './types';

export const fetchApiKeys = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.LIST.LOADING,
  });

  ApiKeyApi.getList(params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.LIST.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};

export const createApiKey = (params) => (dispatch) => {
  dispatch({
    type: actionTypes.CREATE.LOADING,
  });

  ApiKeyApi.create({ apiKey: params })
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.CREATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE.FAILURE,
        message: `notify:general.save.error.${error.status}`,
      });
    });
};

export const updateApiKey = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE.LOADING,
  });

  ApiKeyApi.update(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: response,
        method: 'add',
      });

      dispatch({
        type: actionTypes.UPDATE.SUCCESS,
        payload: response,
        message: 'notify:general.save.success',
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE.FAILURE,
        message: `notify:general.save.error.${error.status}`,
      });
    });
};

export const deleteApiKey = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE.LOADING,
  });

  ApiKeyApi.delete(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.LIST.HYDRATE,
        payload: { data: id },
        method: 'remove',
      });

      dispatch({
        type: actionTypes.DELETE.SUCCESS,
        payload: response,
        message: 'notify:general.delete.success',
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.DELETE.FAILURE,
        message: 'notify:general.delete.error',
      });
    });
};
