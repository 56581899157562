import React from 'react';
import { useTranslate } from '@hooks';
import { Row, Col, FormGroup } from 'reactstrap';
import { map } from 'lodash';
import { FormColumn as Form, Input, SubsectionTitle } from '@dotone/react-ui-core';
import { statSummaryColumns } from '@constants';

const StatSummaryColumnForm = ({ reportType = 'clicks', ...otherProps }) => {
  const { t } = useTranslate('statSummary', 'model');

  const columns = {
    ...statSummaryColumns[reportType],
    extra: ['siteInfos'],
  };

  return (
    <Form {...otherProps}>
      <Row>
        {map(columns, (items, group) => (
          <Col key={group} md={6} lg={4}>
            <SubsectionTitle tag="h6">{t(`statSummary:columnGroup.${group}`)}</SubsectionTitle>
            <FormGroup>
              {items.map((item) => (
                <Input key={item} name={`columns.${item}`} type="checkbox" label={t(item)} />
              ))}
            </FormGroup>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default StatSummaryColumnForm;
