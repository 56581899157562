import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from '@dotone/react-ui-core/hooks';
import { useCurrentUser, useShallowEqualSelector, useTranslate } from '@hooks';
import { updateOrder } from '@actions';
import { orderStatuses as statuses, statApprovals } from '@dotone/react-ui-core/constants';
import { camelCase } from 'lodash';
import { Row, Col, Form, FormGroup as BsFormGroup } from 'reactstrap';
import {
  InputGroup,
  OrderStatusSelect,
  FormAction,
  Button,
  ButtonCancel,
  StatApprovalBadge,
  Input,
  DateTimeSelect,
  Label,
  OrderStatusBadge,
  PercentageInput,
  CurrencyInput,
} from '@dotone/react-ui-core';
import { OfferSearchInput } from '@components/forms';
import OrderConversionSnapshotDetails from './OrderConversionSnapshotDetails';

const editableApprovals = [
  statuses.PENDING,
  statuses.INVALID,
  statuses.REJECTED,
  statuses.FULLRETURN,
];

const FormGroup = ({ label, required, children, ...otherProps }) => (
  <Col sm={6} xl={4}>
    <BsFormGroup required={required} {...otherProps}>
      <Label required>{label}</Label>
      {children}
    </BsFormGroup>
  </Col>
);

const OrderForm = ({ defaultValues: givenDefaultValues = {}, onCancel }) => {
  const { t, tShared } = useTranslate('order', 'model');
  const dispatch = useDispatch();
  const { currency } = useCurrentUser();
  const {
    conversionStepSnapshots = [],
    stepName,
    approval,
    status,
    orderNumber,
    stepLabel,
  } = givenDefaultValues;
  const conversionStepSnapshot = conversionStepSnapshots?.find(
    (cs) => cs.name.toLowerCase() === stepName?.toLowerCase()
  );
  const defaultValues = {
    ...givenDefaultValues,
    step: `${stepLabel} (${stepName})`,
    trueShare: givenDefaultValues.trueShare || conversionStepSnapshot?.trueShare,
  };
  const { handleSubmit, connectForm, watch, setValue } = useForm({ defaultValues });

  const handleSubmitForm = ({ id, ...values }) => {
    dispatch(updateOrder(id, values));
  };

  const { isLoading, status: updateStatus } = useShallowEqualSelector(
    ({ orderUpdate }) => orderUpdate
  );

  const enableUpdate = editableApprovals.includes(approval);

  const [total, trueShare] = watch(['total', 'trueShare']);

  const hintProps =
    approval === statApprovals.INVALID
      ? { tKey: orderNumber, title: tShared(`status.${camelCase(status)}`) }
      : null;

  useEffect(() => {
    if (trueShare) setValue('truePay', ((parseFloat(total) / 100) * trueShare).toFixed(2));
  }, [setValue, total, trueShare]);

  useEffect(() => {
    if (!isLoading && updateStatus === 'success' && onCancel) {
      onCancel();
    }
  }, [isLoading, updateStatus, onCancel]);

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)}>
      <div className="form-inner">
        {connectForm(
          <>
            {conversionStepSnapshot && (
              <OrderConversionSnapshotDetails conversionStepSnapshot={conversionStepSnapshot} />
            )}
            <Row>
              <FormGroup
                label={
                  <>
                    {t('stat:model.transactionId')}{' '}
                    <StatApprovalBadge value={approval} hintProps={hintProps} />
                  </>
                }
              >
                <Input name="affiliateStatId" disabled />
              </FormGroup>
              <FormGroup label={t('common:model.offer')}>
                <OfferSearchInput name="offerId" disabled />
              </FormGroup>
              <FormGroup label={t('stepName')}>
                <Input name="step" disabled />
              </FormGroup>
              <FormGroup
                label={
                  <>
                    {t('common:model.status')} {enableUpdate && <OrderStatusBadge value={status} />}
                  </>
                }
              >
                {enableUpdate ? (
                  <OrderStatusSelect name="status" rules={{ required: true }} />
                ) : (
                  <OrderStatusBadge block value={status} />
                )}
              </FormGroup>
              <FormGroup label={t('recordedAt')}>
                <DateTimeSelect
                  name="recordedAt"
                  timeFormat="HH:mm:ss"
                  inputProps={{ disabled: true }}
                />
              </FormGroup>
              <FormGroup label={t('orderNumber')}>
                <InputGroup
                  name="orderNumber"
                  prepend="#"
                  inputProps={{
                    type: 'text',
                    rules: { required: true },
                    readOnly: true,
                  }}
                />
              </FormGroup>
              <FormGroup label={t('total')}>
                <CurrencyInput name="total" currency={currency} />
              </FormGroup>
              <FormGroup label={t('trueShare')}>
                <PercentageInput name="trueShare" disabled />
              </FormGroup>
              <FormGroup label={t('truePay')}>
                <CurrencyInput name="truePay" currency={currency} disabled />
              </FormGroup>
            </Row>
          </>
        )}
      </div>
      <FormAction align="right">
        {enableUpdate && (
          <Button type="submit" variant="save" color="primary" submitting={isLoading}>
            {t('common:actions.save')}
          </Button>
        )}
        {onCancel && <ButtonCancel disabled={isLoading} onClick={onCancel} />}
      </FormAction>
    </Form>
  );
};

export default OrderForm;
