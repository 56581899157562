import { MktSiteApi } from '@api';
import { mktSiteTypes as actionTypes } from './types';

export const getMktSiteCode = (id, params) => (dispatch) => {
  dispatch({
    type: actionTypes.GET_CODE.LOADING,
  });

  MktSiteApi.getCode(id, params)
    .then((response) => {
      dispatch({
        type: actionTypes.GET_CODE.SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_CODE.FAILURE,
        message: `notify:general.errors.${error.status}`,
      });
    });
};
