import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector, useTranslate } from '@hooks';
import { bulkUpdateTextCreative } from '@actions';
import { Col } from 'reactstrap';
import {
  Form,
  Label,
  ActivePeriodDateTimePicker,
  FormGroup as BsFormGroup,
  Input,
  CreativeStatusSelect,
} from '@dotone/react-ui-core';

const FormGroup = ({ label, ...otherProps }) => (
  <BsFormGroup row>
    <Col md={4} className="text-left text-md-right">
      <Label className="text-custom">{label}</Label>
    </Col>
    <Col md={8} {...otherProps} />
  </BsFormGroup>
);

const TextCreativeBulkEditForm = ({ ids, onCancel, ...otherProps }) => {
  const { t } = useTranslate('textCreative', 'model');
  const dispatch = useDispatch();

  const { isLoading, status } = useShallowEqualSelector(
    ({ textCreativeBulkUpdate }) => textCreativeBulkUpdate
  );

  const handleSubmit = (params) => {
    dispatch(bulkUpdateTextCreative(ids, params));
  };

  useEffect(() => {
    if (!isLoading && status === 'success') {
      onCancel();
    }
  }, [isLoading, status, onCancel]);

  return (
    <Form
      forModal
      onSubmit={handleSubmit}
      onCancel={onCancel}
      submitting={isLoading}
      submitLabel={t('::common:actions.submit')}
      {...otherProps}
    >
      <ActivePeriodDateTimePicker
        names={{
          start: 'activeDateStart',
          end: 'activeDateEnd',
          infinity: 'isInfinityTime',
        }}
        rules={{ required: false }}
      />
      <FormGroup label={t('clientUrl')} required>
        <Input type="text" name="clientUrl" />
      </FormGroup>
      <FormGroup label={t('status')}>
        <CreativeStatusSelect variant="edit" name="status" />
      </FormGroup>
    </Form>
  );
};

export default TextCreativeBulkEditForm;
